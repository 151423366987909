import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import { RootState } from "../state"; // Import RootState type
import { useSelector } from "react-redux"; // Import useSelector
import { useTranslation } from "react-i18next";

interface TaskSlotsPopUpProps {
  isNew: boolean;
  editRow: {
    name: string;
    start: number;
    end: number;
    id?: string;
    idnew: string;
  } | null;
  closeModal: () => void;
  handleAddTaskSlot: (values: any) => void;
  handleEditTaskSlot: (values: any) => void;
}

const TaskSlotsPopUp: React.FC<TaskSlotsPopUpProps> = ({
  isNew,
  editRow,
  closeModal,
  handleAddTaskSlot,
  handleEditTaskSlot,
}) => {
  const { t } = useTranslation();
  const minDistance: number = useSelector(
    (state: RootState) => state.graph.settings.fromDistance
  );
  const maxDistance: number = useSelector(
    (state: RootState) => state.graph.settings.toDistance
  );

  const initialValues = {
    name: isNew ? "" : editRow!.name,
    start: isNew ? "" : editRow!.start,
    end: isNew ? "" : editRow!.end,
    idnew: isNew ? "" : editRow!.idnew,
    id: isNew ? "" : editRow!.id,
  };

  // const validationSchema = Yup.object().shape({
  //   name: Yup.string().required("Le nom est requis"),
  //   start: Yup.number()
  //     .required("Le Pk de début est requis")
  //     .min(
  //       minDistance,
  //       `Le Pk de début doit être supérieur ou égal à ${minDistance}`
  //     )
  //     .max(
  //       maxDistance,
  //       `Le Pk de début doit être inférieur ou égal à ${maxDistance}`
  //     ),
  //   end: Yup.number()
  //     .required("Le Pk de fin est requise")
  //     .min(
  //       minDistance,
  //       `Le Pk de fin doit être supérieure ou égale à ${minDistance}`
  //     )
  //     .max(
  //       maxDistance,
  //       `Le Pk de fin doit être inférieure ou égale à ${maxDistance}`
  //     )
  //     .test(
  //       "is-greater-than-start",
  //       "Le Pk de fin doit être supérieur au Pk de début",
  //       function (end) {
  //         const start = this.parent.start; // Get the value of 'start' from the form data

  //         // Check if 'end' is greater than 'start'
  //         return end > start;
  //       }
  //     ),
  // });

  let validationSchema = Yup.object().shape({
    name: Yup.string().required(t("taskSlotsPopUp.errors.required")),
    start: Yup.number()
      .required(t("taskSlotsPopUp.errors.required"))
      .min(minDistance, t("taskSlotsPopUp.errors.minDistance", { minDistance }))
      .max(
        maxDistance,
        t("taskSlotsPopUp.errors.maxDistance", { maxDistance })
      ),
    end: Yup.number()
      .required(t("taskSlotsPopUp.errors.required"))
      .min(minDistance, t("taskSlotsPopUp.errors.minDistance", { minDistance }))
      .max(maxDistance, t("taskSlotsPopUp.errors.maxDistance", { maxDistance }))
      .test(
        "is-greater-than-start",
        t("taskSlotsPopUp.errors.greaterThanStart"),
        function (end) {
          const start = this.parent.start;
          return end > start;
        }
      ),
  });

  const onSubmit = (values: any) => {
    // Handle form submission (add or edit)
    if (isNew) {
      handleAddTaskSlot(values);
    } else {
      handleEditTaskSlot({ ...values, id: editRow!.idnew });
    }

    closeModal();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-white bg-opacity-80 dark:bg-gray-700 dark:bg-opacity-20">
      <div className="bg-white p-6 rounded shadow-md w-96 dark:bg-gray-700 ">
        <div className="text-2xl font-semibold mb-4 dark:bg-gray-700 dark:text-white">
          {isNew
            ? t("taskSlotsPopUp.titles.add")
            : t("taskSlotsPopUp.titles.edit")}
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            resetForm,
            isValid,
          }) => (
            <Form>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2  dark:text-white">
                  {t("taskSlotsPopUp.labels.name")}
                </label>
                <Field
                  type="text"
                  name="name"
                  placeholder={t("taskSlotsPopUp.placeholders.name")}
                  className={`border ${
                    errors.name ? "border-red-500" : "border-gray-300"
                  } rounded p-2 w-full block w-full rounded-lg border border-gray-300  bg-gray-50 p-2.5  text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500`}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500 mt-2"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2  dark:text-white">
                  {t("taskSlotsPopUp.labels.start")}
                </label>
                <Field
                  type="number"
                  name="start"
                  placeholder={t("taskSlotsPopUp.placeholders.start")}
                  className={`border ${
                    errors.start ? "border-red-500" : "border-gray-300"
                  } rounded p-2 w-full block w-full rounded-lg border border-gray-300  bg-gray-50 p-2.5  text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500`}
                />
                <ErrorMessage
                  name="start"
                  component="div"
                  className="text-red-500 mt-2"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2  dark:text-white">
                  {t("taskSlotsPopUp.labels.end")}
                </label>
                <Field
                  type="number"
                  name="end"
                  placeholder={t("taskSlotsPopUp.placeholders.end")}
                  className={`border ${
                    errors.end ? "border-red-500" : "border-gray-300"
                  } rounded p-2 w-full block w-full rounded-lg border border-gray-300  bg-gray-50 p-2.5  text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500`}
                />
                <ErrorMessage
                  name="end"
                  component="div"
                  className="text-red-500 mt-2"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={!isValid}
                  className="bg-blue-500 text-white rounded px-4 py-2 mr-2 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                >
                  {isNew
                    ? t("taskSlotsPopUp.buttons.add")
                    : t("taskSlotsPopUp.buttons.save")}
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  className="bg-gray-300 text-gray-600 rounded px-4 py-2 hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-300"
                >
                  {t("taskSlotsPopUp.buttons.cancel")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TaskSlotsPopUp;
